import { render, staticRenderFns } from "./act_work_performed_income_v1.vue?vue&type=template&id=4e985700&scoped=true&"
import script from "./act_work_performed_income_v1.vue?vue&type=script&lang=js&"
export * from "./act_work_performed_income_v1.vue?vue&type=script&lang=js&"
import style0 from "./act_work_performed_income_v1.vue?vue&type=style&index=0&id=4e985700&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e985700",
  null
  
)

export default component.exports