<template>
  <div>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        temporary
        v-model="setting_dialog"
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-row>
                <v-subheader class="success--text" style="border-bottom: 1px solid var(--v-success-base); width: 100%; height: 40px; padding-top: 6px">
                  Налаштування друку
                </v-subheader>
                <v-col cols="12" sm="6">
                  <v-switch hide-details
                            color="grey darken-1"
                            v-model="document_setting.duplicate_on_one_page"
                            label="2 копії на одну сторінку"
                            class="mt-0"
                            @change="duplicateOnOnePageChange"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-switch hide-details
                            color="grey darken-1"
                            v-model="document_setting.duplicate_on_differance_page"
                            label="2 копії на різні сторінки"
                            class="mt-0"
                            @change="duplicateOnDifferancePageChange"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea color="success"
                              label="Додатковий текст"
                              auto-grow
                              rows="2"
                              placeholder="Введіть бажаний текст для відображення у документі"
                              v-model="document_setting.additional_text"
                              hide-details
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page page-a4" style="height: auto !important; min-height: 215mm; float: none !important;">
      <div class="document-print-form" style="float: none !important;">
        <template v-if="document_setting.duplicate_on_one_page">
          <template v-for="copy in 2">
            <table class="document-table-form table-separator" :key="`copy-${copy}`" v-if="document_data.date">
              <caption class="mb-3">
                <div class="document-title title-underline">
                  {{
                    `Акт здачі-приймання робіт (надання послуг) №${document_data.number} від ${formatDate(document_data.date, 'DD MMMM YYYY')} р. за ${formatDate(document_data.date, 'MMMM YYYY')} р.`
                  }}
                </div>
                <div class="document-body">
                  <div class="document-body-line text-ident">
                    {{
                      `Ми, нижче підписані, представники Замовника ${organization.full_name}, з одної сторони, і
              представники Виконавця ${document_data.contractor_full_name}, з
              другої сторони, склали цей акт про те, що на підставі приведених документів: ${document_data.contract_name} №${document_data.contract_number || ''} від
              ${formatDate(document_data.contract_date)}`
                    }}
                  </div>
                  <div class="document-body-line mt-1" v-if="document_setting.additional_text">
                    {{ document_setting.additional_text }}
                  </div>
                  <div class="document-body-line mt-1 font-weight-bold">
                    Виконавцем були проведені наступні роботи (надані такі послуги):
                  </div>
                </div>
              </caption>
              <thead>
              <tr>
                <th style="width: 50px">№</th>
                <th>Послуга</th>
                <th style="width: 60px">Од.вим.</th>
                <th style="width: 100px">Ціна</th>
                <th style="width: 120px">К-сть</th>
                <th style="width: 140px">Сума</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, idx) in document_data.table" :key="idx">
                <td style="width: 50px; text-align: center">
                  {{ idx + 1 }}
                </td>
                <td>{{ item.nomenclature }}</td>
                <td style="width: 60px">грн.</td>
                <td style="width: 100px; text-align: center">
                  {{ item.price }}
                </td>
                <td style="width: 120px; text-align: center">
                  {{ item.count }}
                </td>
                <td style="width: 140px; text-align: right">
                  {{ item.sum  | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="total">
                <td colspan="5" style="text-align: right; padding-top: 4px">Всього:</td>
                <td style="text-align: right; padding-top: 4px">
                  {{ document_data.total_sum | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="total">
                <td colspan="5" style="text-align: right">ПДВ:</td>
                <td style="text-align: right">
                  {{ document_data.total_tax | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="total">
                <td colspan="5" style="text-align: right">До оплати:</td>
                <td style="text-align: right">
                  {{ document_data.total_all | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="without-border">
                <td colspan="5" class="pt-3" style="font-size: .9rem; padding-left: 0">
                  {{ convertNumberToString(document_data.total_all) }}
                </td>
              </tr>
              <tr class="without-border">
                <td colspan="5" style="font-size: .9rem; padding-left: 0; border-bottom: 2px solid black !important;">
                  Замовник претензій по об’єму, якості і строкам надання послуг (робіт) немає
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="3" style="padding-top: 6px; font-size: .9rem; font-weight: bold">
                  Від виконавця
                </td>
                <td colspan="3" style="padding-top: 6px; font-size: .9rem; font-weight: bold">
                  Від замовника
                </td>
              </tr>
              <tr>
                <td colspan="3" style="padding-bottom: 3px; font-size: .9rem;">
                  <div style="width: 90%; height: 20px; margin-right: 60px">
                    {{ getResponsePerson('contractor', 'director') }}
                  </div>
                  <div style="height: 1px; background-color: black; margin-right: 60px"></div>
                </td>
                <td colspan="3" style="padding-bottom: 3px; font-size: .9rem;">
                  <div style="width: 90%; height: 20px;">
                    {{ getResponsePerson('organization', 'director') }}
                  </div>
                  <div style="height: 1px; background-color: black;"></div>
                </td>
              </tr>
              <tr>
                <td colspan="3" style="padding-top: 2px; font-size: .9rem;">
                  <div
                      style="width: 90%; min-height: 18px; margin-right: 60px; font-size: .8rem; font-weight: bold; line-height: .9rem; vertical-align: text-bottom;">
                    {{ print_data.organization_details_for_document }}
                  </div>
                </td>
                <td colspan="3" style="padding-top: 2px; font-size: .8rem; font-weight: bold; vertical-align: text-bottom;">
                  <div style="width: 90%; min-height: 18px; line-height: .9rem">
                    {{ (document_data.contractor_print_data || {}).print_data_for_document }}
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3" style="padding-top: 2px; font-size: .9rem; vertical-align: text-bottom;">
                  <div style="width: 90%; margin-right: 60px; font-size: .8rem; line-height: 1rem">
                    {{ organization.short_name }}
                  </div>
                </td>
                <td colspan="3" style="padding-top: 2px; font-size: .8rem; line-height: 1rem; vertical-align: text-bottom;">
                  <div style="width: 90%;">
                    {{ document_data.contractor_short_name }}
                  </div>
                </td>
              </tr>
              </tfoot>
            </table>
          </template>
        </template>
        <template v-if="document_setting.duplicate_on_differance_page">
          <template v-for="copy in 2">
            <table class="document-table-form table-separator" :key="`copy-${copy}`" v-if="document_data.date">
              <caption class="mb-3">
                <div class="document-title title-underline">
                  {{
                    `Акт здачі-приймання робіт (надання послуг) №${document_data.number} від ${formatDate(document_data.date, 'DD MMMM YYYY')} р. за ${formatDate(document_data.date, 'MMMM YYYY')} р.`
                  }}
                </div>
                <div class="document-body">
                  <div class="document-body-line text-ident">
                    {{
                      `Ми, нижче підписані, представники Замовника ${organization.full_name}, з одної сторони, і
              представники Виконавця ${document_data.contractor_full_name}, з
              другої сторони, склали цей акт про те, що на підставі приведених документів: ${document_data.contract_name} №${document_data.contract_number || ''} від
              ${formatDate(document_data.contract_date)}`
                    }}
                  </div>
                  <div class="document-body-line mt-1" v-if="document_setting.additional_text">
                    {{ document_setting.additional_text }}
                  </div>
                  <div class="document-body-line mt-1 font-weight-bold">
                    Виконавцем були проведені наступні роботи (надані такі послуги):
                  </div>
                </div>
              </caption>
              <thead>
              <tr>
                <th style="width: 50px">№</th>
                <th>Послуга</th>
                <th style="width: 60px">Од.вим.</th>
                <th style="width: 100px">Ціна</th>
                <th style="width: 120px">К-сть</th>
                <th style="width: 140px">Сума</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, idx) in document_data.table" :key="idx">
                <td style="width: 50px; text-align: center">
                  {{ idx + 1 }}
                </td>
                <td>{{ item.nomenclature }}</td>
                <td style="width: 60px">грн.</td>
                <td style="width: 100px; text-align: center">
                  {{ item.price }}
                </td>
                <td style="width: 120px; text-align: center">
                  {{ item.count }}
                </td>
                <td style="width: 140px; text-align: right">
                  {{ item.sum  | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="total">
                <td colspan="5" style="text-align: right; padding-top: 4px">Всього:</td>
                <td style="text-align: right; padding-top: 4px">
                  {{ document_data.total_sum | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="total">
                <td colspan="5" style="text-align: right">ПДВ:</td>
                <td style="text-align: right">
                  {{ document_data.total_tax | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="total">
                <td colspan="5" style="text-align: right">До оплати:</td>
                <td style="text-align: right">
                  {{ document_data.total_all | formatToFixed | formatNumber }}
                </td>
              </tr>
              <tr class="without-border">
                <td colspan="5" class="pt-3" style="font-size: .9rem; padding-left: 0">
                  {{ convertNumberToString(document_data.total_all) }}
                </td>
              </tr>
              <tr class="without-border">
                <td colspan="5" style="font-size: .9rem; padding-left: 0; border-bottom: 2px solid black !important;">
                  Замовник претензій по об’єму, якості і строкам надання послуг (робіт) немає
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="3" style="padding-top: 6px; font-size: .9rem; font-weight: bold">
                  Від виконавця
                </td>
                <td colspan="3" style="padding-top: 6px; font-size: .9rem; font-weight: bold">
                  Від замовника
                </td>
              </tr>
              <tr>
                <td colspan="3" style="padding-bottom: 3px; font-size: .9rem;">
                  <div style="width: 90%; height: 20px; margin-right: 60px">
                    {{ getResponsePerson('contractor', 'director') }}
                  </div>
                  <div style="height: 1px; background-color: black; margin-right: 60px"></div>
                </td>
                <td colspan="3" style="padding-bottom: 3px; font-size: .9rem;">
                  <div style="width: 90%; height: 20px;">
                    {{ getResponsePerson('organization', 'director') }}
                  </div>
                  <div style="height: 1px; background-color: black;"></div>
                </td>
              </tr>
              <tr>
                <td colspan="3" style="padding-top: 2px; font-size: .9rem;">
                  <div
                      style="width: 90%; min-height: 18px; margin-right: 60px; font-size: .8rem; font-weight: bold; line-height: .9rem; vertical-align: text-bottom;">
                    {{ print_data.organization_details_for_document }}
                  </div>
                </td>
                <td colspan="3" style="padding-top: 2px; font-size: .8rem; font-weight: bold; vertical-align: text-bottom;">
                  <div style="width: 90%; min-height: 18px; line-height: .9rem">
                    {{ (document_data.contractor_print_data || {}).print_data_for_document }}
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3" style="padding-top: 2px; font-size: .9rem; vertical-align: text-bottom;">
                  <div style="width: 90%; margin-right: 60px; font-size: .8rem; line-height: 1rem">
                    {{ organization.short_name }}
                  </div>
                </td>
                <td colspan="3" style="padding-top: 2px; font-size: .8rem; line-height: 1rem; vertical-align: text-bottom;">
                  <div style="width: 90%;">
                    {{ document_data.contractor_short_name }}
                  </div>
                </td>
              </tr>
              </tfoot>
            </table>
            <template v-if="copy === 1">
              <p class="document-separator" :key="`sep-${copy}`">&nbsp;</p>
            </template>
          </template>
        </template>
        <template v-if="!document_setting.duplicate_on_differance_page && !document_setting.duplicate_on_one_page">
          <table class="document-table-form table-separator" v-if="document_data.date">
            <caption class="mb-3">
              <div class="document-title title-underline">
                {{
                  `Акт здачі-приймання робіт (надання послуг) №${document_data.number} від ${formatDate(document_data.date, 'DD MMMM YYYY')} р. за ${formatDate(document_data.date, 'MMMM YYYY')} р.`
                }}
              </div>
              <div class="document-body">
                <div class="document-body-line text-ident">
                  {{
                    `Ми, нижче підписані, представники Замовника ${organization.full_name}, з одної сторони, і
              представники Виконавця ${document_data.contractor_full_name}, з
              другої сторони, склали цей акт про те, що на підставі приведених документів: ${document_data.contract_name} №${document_data.contract_number || ''} від
              ${formatDate(document_data.contract_date)}`
                  }}
                </div>
                <div class="document-body-line mt-1" v-if="document_setting.additional_text">
                  {{ document_setting.additional_text }}
                </div>
                <div class="document-body-line mt-1 font-weight-bold">
                  Виконавцем були проведені наступні роботи (надані такі послуги):
                </div>
              </div>
            </caption>
            <thead>
            <tr>
              <th style="width: 50px">№</th>
              <th>Послуга</th>
              <th style="width: 60px">Од.вим.</th>
              <th style="width: 100px">Ціна</th>
              <th style="width: 120px">К-сть</th>
              <th style="width: 140px">Сума</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, idx) in document_data.table" :key="idx">
              <td style="width: 50px; text-align: center">
                {{ idx + 1 }}
              </td>
              <td>{{ item.nomenclature }}</td>
              <td style="width: 60px">грн.</td>
              <td style="width: 100px; text-align: center">
                {{ item.price }}
              </td>
              <td style="width: 120px; text-align: center">
                {{ item.count }}
              </td>
              <td style="width: 140px; text-align: right">
                {{ item.sum  | formatToFixed | formatNumber }}
              </td>
            </tr>
            <tr class="total">
              <td colspan="5" style="text-align: right; padding-top: 4px">Всього:</td>
              <td style="text-align: right; padding-top: 4px">
                {{ document_data.total_sum | formatToFixed | formatNumber }}
              </td>
            </tr>
            <tr class="total">
              <td colspan="5" style="text-align: right">ПДВ:</td>
              <td style="text-align: right">
                {{ document_data.total_tax | formatToFixed | formatNumber }}
              </td>
            </tr>
            <tr class="total">
              <td colspan="5" style="text-align: right">До оплати:</td>
              <td style="text-align: right">
                {{ document_data.total_all | formatToFixed | formatNumber }}
              </td>
            </tr>
            <tr class="without-border">
              <td colspan="5" class="pt-3" style="font-size: .9rem; padding-left: 0">
                {{ convertNumberToString(document_data.total_all) }}
              </td>
            </tr>
            <tr class="without-border">
              <td colspan="5" style="font-size: .9rem; padding-left: 0; border-bottom: 2px solid black !important;">
                Замовник претензій по об’єму, якості і строкам надання послуг (робіт) немає
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td colspan="3" style="padding-top: 6px; font-size: .9rem; font-weight: bold">
                Від виконавця
              </td>
              <td colspan="3" style="padding-top: 6px; font-size: .9rem; font-weight: bold">
                Від замовника
              </td>
            </tr>
            <tr>
              <td colspan="3" style="padding-bottom: 3px; font-size: .9rem;">
                <div style="width: 90%; height: 20px; margin-right: 60px">
                  {{ getResponsePerson('contractor', 'director') }}
                </div>
                <div style="height: 1px; background-color: black; margin-right: 60px"></div>
              </td>
              <td colspan="3" style="padding-bottom: 3px; font-size: .9rem;">
                <div style="width: 90%; height: 20px;">
                  {{ getResponsePerson('organization', 'director') }}
                </div>
                <div style="height: 1px; background-color: black;"></div>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="padding-top: 2px; font-size: .9rem;">
                <div
                    style="width: 90%; min-height: 18px; margin-right: 60px; font-size: .8rem; font-weight: bold; line-height: .9rem; vertical-align: text-bottom;">
                  {{ (document_data.contractor_print_data || {}).print_data_for_document }}
                </div>
              </td>
              <td colspan="3" style="padding-top: 2px; font-size: .8rem; font-weight: bold; vertical-align: text-bottom;">
                <div style="width: 90%; min-height: 18px; line-height: .9rem">
                  {{ print_data.organization_details_for_document }}
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3" style="padding-top: 2px; font-size: .9rem; vertical-align: text-bottom;">
                <div style="width: 90%; margin-right: 60px; font-size: .8rem; line-height: 1rem">
                  {{ document_data.contractor_short_name }}
                </div>
              </td>
              <td colspan="3" style="padding-top: 2px; font-size: .8rem; line-height: 1rem; vertical-align: text-bottom;">
                <div style="width: 90%;">
                  {{ organization.short_name }}
                </div>
              </td>
            </tr>
            </tfoot>
          </table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {convertNumberToLetterString} from "@/utils/accounting";
import documentPrintAPI from "@/utils/axios/accounting/documents/act_work_performed_income"
import legacyDocumentsAPI from "@/utils/axios/legacy_documents"
import {formatDate} from "@/filters";
import {endOfMonth} from "@/utils/icons";
import {mapGetters} from "vuex";

export default {
  name: "act_work_performed_income_v1",
  props: {
    organization: {
      type: Object,
      default() {
        return {}
      }
    },
    print_data: {
      type: Object,
      default() {
        return {}
      }
    },
    document_id: {
      type: Number,
      default: null
    },
    global_settings: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      legacy: 'isLegacy'
    }),
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    }
  },
  data() {
    return {
      setting_dialog: this.global_settings,
      settings_watcher: null,
      document_setting: {
        additional_text: '',
        duplicate_on_one_page: false,
        duplicate_on_differance_page: false
      },
      document_data: {}
    }
  },
  methods: {
    duplicateOnOnePageChange(payload) {
      if (payload) {
        this.document_setting.duplicate_on_differance_page = false
      }
    },
    duplicateOnDifferancePageChange(payload) {
      if (payload) {
        this.document_setting.duplicate_on_one_page = false
      }
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    formatDate,
    getResponsePerson(table, category, field='short_name') {
      if (table === 'organization') {
        return ((this.print_data?.response_people || []).find(i => i.category === category) || {})?.[field] || ''
      } else {
        return (this.document_data?.contractor_print_data || {})?.response_persons?.[category]?.[field] || ''
      }
    },
    getBalanceTitle() {
      const end_month = endOfMonth(this.document_data.month)
      const date = this.document_setting.balance_end ? end_month : this.document_data.month
      const balance = this.document_setting.balance_end
          ? this.document_data.balance_end : this.document_data.balance_start
      let balance_title = ''
      if (balance < 0) {
        balance_title = 'Переплата станом на'
      } else {
        balance_title = 'Заборгованість станом на'
      }

      return `${balance_title} ${formatDate(date, 'DD.MM.YYYY')}`
    },
    convertNumberToString(payload) {
      return convertNumberToLetterString(payload)
    }
  },
  watch: {
    modal: {
      immediate: true,
      handler(payload) {
        this.document_setting.additional_text = ''

        if (payload) {
          if (this.legacy) {
            legacyDocumentsAPI.get_setting_by_document({document_name: 'DOCUMENT_ACT_WORK_PERFORMED_INCOME'})
                .then(response => response.data)
                .then(data => {
                  const server_filters = JSON.parse(data)
                  Object.keys(this.document_setting).forEach(key => {
                    if (server_filters[key] !== undefined) {
                      this.document_setting[key] = server_filters[key]
                    }
                  })
                })
          } else {
            this.document_setting.show_balance = false
          }


          documentPrintAPI.get_document_for_print(this.document_id)
              .then(response => response.data)
              .then(data => this.document_data = data)
        } else {
          this.document_data = {}
        }
      }
    }
  },
  created() {
    this.watch_settings()
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
.document-print-form {
  width: 100%;
  font-family: Arial, Calibri, serif;
  padding: 4px 12px;
  float: none !important;

  .document-table-form {
    width: 100%;
    border-collapse: collapse;
    float: none !important;

    thead {
      tr {
        border: 1px solid grey;

        th {
          border: 1px solid grey;
          padding: 2px 6px;
          font-size: .8rem;
          font-weight: bold;
          line-height: 1.05rem;
        }
      }
    }

    tbody {
      tr {
        border: 1px solid grey;

        td {
          border: 1px solid grey;
          padding: 2px 6px;
          font-size: .8rem;
          line-height: .94rem;
        }
      }

      .without-border {
        border: none !important;

        td {
          border: none !important;
        }
      }

      .total {
        border: none !important;

        td {
          font-weight: bold !important;
          font-size: .9rem;
          border: none !important;
        }
      }
    }
  }

  .document-title {
    font-size: 1.02rem;
    font-weight: 600;
    text-align: left;
    line-height: 1.3rem;
  }

  .title-underline {
    border-bottom: 2px solid;
  }

  .text-ident {
    text-indent: 30px;
  }

  .document-body {
    font-size: .8rem;
    text-align: justify;
    margin-top: 12px;
    font-weight: 500;
  }
}
</style>